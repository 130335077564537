import React from "react"
import {
  Box,
  Flex,
  Text,
  useDisclosure,
  Image,
  Collapse,
} from "@chakra-ui/core"
import SectionHeader from "../SectionHeader"
import questionIcon from "../../../images/patch-question.svg"
import FaqToggleIcon from "../FaqToggleIcon"

interface QAProps {
  question: string
  answer: string
}

const QAComponent = (props: QAProps) => {
  const { isOpen, onToggle } = useDisclosure()

  return (
    <Box p={4} my={4} boxShadow="rgba(0, 0, 0, 0.08) 0px 6px 10px">
      <Box
        cursor="pointer"
        onClick={onToggle}
        fontFamily="Mukta Malar"
        fontWeight="bold"
      >
        <Flex align="center" justify="space-between">
          <Flex align="center">
            <Image height={["1.2rem", "1.4rem", "1.6rem"]} src={questionIcon} />
            <Text fontSize={["md", "lg", "xl"]} fontWeight="bold" ml={3}>
              {props.question}
            </Text>
          </Flex>
          <Box flex="0 0 auto">
            <FaqToggleIcon active={isOpen} />
          </Box>
        </Flex>
      </Box>

      <Collapse duration={500} isOpen={isOpen}>
        <Box fontSize={["sm", "md", "lg"]} py={3} px={10} color="#818181">
          {props.answer}
        </Box>
      </Collapse>
    </Box>
  )
}

const KelviBathilgalSection = (): JSX.Element => {
  return (
    <Box backgroundImage="linear-gradient(180deg, #FFFFFF 0%, #FCFCFCFC 20%, #E0E0E01D 53%, #FFFFFF60 89%, #FFFFFF 100%)">
      <Box mt={5} mb={10} p={5} maxW="1000px" mx="auto">
        <Box mb={[0, 12, 24]}>
          <SectionHeader title="கேள்வி பதில்கள்" />
        </Box>
        <Box>
          <QAComponent
            question="நீங்கள் பாடநெறியை ஆங்கிலத்தில் கற்பிப்பீர்களா?"
            answer="ஆம், நாங்கள் தமிழில் மட்டுமே கற்பிப்போம். நாங்கள் கற்பிப்பதை எல்லோரும் புரிந்துகொள்வார்கள்"
          />
        </Box>
        <Box>
          <QAComponent
            question="நீங்கள் பாடநெறியை ஆங்கிலத்தில் கற்பிப்பீர்களா?"
            answer="ஆம், நாங்கள் தமிழில் மட்டுமே கற்பிப்போம். நாங்கள் கற்பிப்பதை எல்லோரும் புரிந்துகொள்வார்கள்"
          />
        </Box>
        <Box>
          <QAComponent
            question="நீங்கள் பாடநெறியை ஆங்கிலத்தில் கற்பிப்பீர்களா?"
            answer="ஆம், நாங்கள் தமிழில் மட்டுமே கற்பிப்போம். நாங்கள் கற்பிப்பதை எல்லோரும் புரிந்துகொள்வார்கள்"
          />
        </Box>
      </Box>
    </Box>
  )
}

export default KelviBathilgalSection
