import React from "react"
import { Box } from "@chakra-ui/core"
import styled from "@emotion/styled"

interface FaqToggleIconProps {
  active: boolean
}

const StyledBox = styled(Box)<FaqToggleIconProps>`
  cursor: pointer;

  &:before,
  &:after {
    content: "";
    border-radius: 1em;
    position: absolute;
    background-color: #707070;
    transition: transform 0.25s ease-out;
  }

  /* Vertical line */
  &:before {
    top: 0;
    left: 50%;
    width: 2px;
    height: 100%;
    margin-left: -1px;
  }

  /* horizontal line */
  &:after {
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    margin-top: -1px;
  }
  &:before {
    transform: ${props => (props.active ? "rotate(90deg)" : "rotate(0deg)")};
  }
  &:after {
    transform: ${props => (props.active ? "rotate(180deg)" : "rotate(0deg)")};
  }
`

const FaqToggleIcon = (props: FaqToggleIconProps): JSX.Element => {
  return (
    <StyledBox
      display="inline-block"
      fontSize="lg"
      color="white"
      position="relative"
      width="1rem"
      height="1rem"
      active={props.active}
    />
  )
}

export default FaqToggleIcon
