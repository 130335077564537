import React from "react"
import { Box } from "@chakra-ui/core"
import PhysicalCourseSection from "../components/PhysicalCourse/PhysicalCourseSection"
import PageBanner from "../components/PageBanner"
import RegisterSection from "../components/RegisterSection"
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import BestTeachingSection from "../components/Home/BestTeachingSection"
import KelviBathilgalSection from "../components/Ias/KelviBathilgalSection"

const PhysicalCourse = () => {
  return (
    <Box>
      <SEO title="Physical Classes" />
      <Layout>
        <PageBanner bannerText="ஆட்சித்தமிழுக்கு வாருங்கள் அரசு பணியாளராய் மாறுங்கள்" />
        <PhysicalCourseSection />
        <Box py={1} bg="#F8F8F8">
          <BestTeachingSection />
        </Box>
        <KelviBathilgalSection />
        <RegisterSection />
      </Layout>
    </Box>
  )
}
export default PhysicalCourse
