import React from "react"
import { Box, Text, SimpleGrid, Heading, Stack, Flex } from "@chakra-ui/core"
import { Tick } from "@emotion-icons/typicons/Tick"
import SectionTitle from "../SectionTitle"
import SectionHeader from "../SectionHeader"
import Button from "../Button"
import CourseBg from "../../../images/courseBg.svg"
import VideoSlider from "../VideoSlider"

interface PhysicalCourseSectionCardProps {
  heading: string
  price: string
  period: string
  descriptionArray: string[]
}

const PhysicalCourseSectionCard = (props: PhysicalCourseSectionCardProps) => {
  return (
    <Box
      bg="#FAFAFA"
      mx="auto"
      my={2}
      p={3}
      boxShadow="0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)"
      borderRadius="1.5em"
      textAlign="center"
    >
      <Stack mb={2} justify="center" isInline spacing={4}>
        <Box width="1.2rem" height="1.2rem" borderRadius="100%" bg="#0087FC" />
        <Box width="1.2rem" height="1.2rem" borderRadius="100%" bg="#0087FC" />
        <Box width="1.2rem" height="1.2rem" borderRadius="100%" bg="#0087FC" />
        <Box width="1.2rem" height="1.2rem" borderRadius="100%" bg="#0087FC" />
        <Box width="1.2rem" height="1.2rem" borderRadius="100%" bg="#0087FC" />
        <Box width="1.2rem" height="1.2rem" borderRadius="100%" bg="#0087FC" />
      </Stack>
      <Heading color="#EB1D27" fontSize="3xl" fontWeight="bold" pt={2}>
        {props.heading}
      </Heading>
      <Heading fontSize="4xl" fontWeight="bold">
        {props.price}
      </Heading>
      <Text fontSize="sm" fontWeight="bold" color="#707070BC">
        {props.period}
      </Text>
      <Box mt={4} textAlign="left" px={2} color="#797979">
        {props.descriptionArray.map(description => {
          return (
            <Stack isInline pl={1} py={1}>
              <Flex
                align="center"
                justify="center"
                bg="#0cbf5f"
                w="20px"
                h="20px"
                borderRadius="50%"
                mr={1}
              >
                <Tick size="15px" color="#ebebeb" />
              </Flex>
              <Box>{description}</Box>
            </Stack>
          )
        })}
      </Box>
      <Box mt={6} mb={4}>
        <Button borderRadius="0.5em" bg="#5057FA" color="#fff">
          மேலும் அறிக
        </Button>
      </Box>
    </Box>
  )
}

const PhysicalCourseSection = () => {
  return (
    <Box>
      <SectionHeader title="நேரடிப்பயிற்சி" />
      <VideoSlider arrowColor="#000" />
      <Box my={10}>
        <SectionTitle title="வகுப்புகள்" />
      </Box>
      <Box py={10} bgImage={`url(${CourseBg})`}>
        <SimpleGrid
          columns={[1, 2, null, 3]}
          spacing={10}
          mt={2}
          mx="auto"
          maxW="1100px"
        >
          <PhysicalCourseSectionCard
            heading="தினசரி வகுப்பு"
            price="1,20,000"
            period="/ 1 ஆண்டு"
            descriptionArray={[
              "மாலை 6 முதல் 10 மணி வரை",
              "தேர்வு மாதிரி புத்தக பொருள்",
              "தேர்வுத் தொடர்",
              "தேர்வு மாதிரி புத்தக பொருள்",
            ]}
          />
          <PhysicalCourseSectionCard
            heading="தினசரி வகுப்பு"
            price="1,20,000"
            period="/ 1 ஆண்டு"
            descriptionArray={[
              "மாலை 6 முதல் 10 மணி வரை",
              "தேர்வு மாதிரி புத்தக பொருள்",
              "தேர்வுத் தொடர்",
              "தேர்வு மாதிரி புத்தக பொருள்",
            ]}
          />
          <PhysicalCourseSectionCard
            heading="தினசரி வகுப்பு"
            price="1,20,000"
            period="/ 1 ஆண்டு"
            descriptionArray={[
              "மாலை 6 முதல் 10 மணி வரை",
              "தேர்வு மாதிரி புத்தக பொருள்",
              "தேர்வுத் தொடர்",
              "தேர்வு மாதிரி புத்தக பொருள்",
            ]}
          />
        </SimpleGrid>
      </Box>
    </Box>
  )
}
export default PhysicalCourseSection
